import API from './api.js';
import template from './../templates/broadbandAvailability.hbs';

export default class BroadbandSearch {
	constructor(element) {
		this.form = element.querySelector('.form');
		this.postcode = element.querySelector('#postcode');
		this.address = element.querySelector('#addressSelector');
		this.broadbandCard = element.querySelector('#broadbandCard');
		this.test = element.querySelector('.choices__item--selectable');

		this.loadingIndicator = element.querySelector('.loading');
		this.searchButton = element.querySelector('.searchBroadbandButton');
		this.hasSearched = false;

		this.address.addEventListener('change', e => {
			this.searchButton.style.display = 'block';
		});

		this.searchButton.addEventListener('click', e => {

			if (this.hasSearched) {
				document.querySelector('.tariffInformation').remove();
				this.broadbandCard.innerHTML = '<img src="https://s3-eu-west-1.amazonaws.com/glide-public-static-content/website/wp-content/uploads/2020/05/Ellipsis.svg" class="loading">';
				document.querySelector('.loading').style.display = 'block';
			}

			this.searchBroadbandAvailability(this.address.value, this.broadbandCard);
		});

		this.tariffs = {};
		this.copy = {
			deemed: null,
			fixed: null,
		};

	}

	searchBroadbandAvailability(house, broadbandCard) {

		if (!this.hasSearched) {
			broadbandCard.style.display = 'block';
		}

		this.searchButton.disabled = true;

		this.loadingIndicator.style.display = 'block';
		this.hasSearched = true;

		API.getBroadbandAvailability(house).then(response => {

			this.loadingIndicator.style.display = 'none';

			broadbandCard.innerHTML = template({
				virginBroadband: response['virgin-broadband'],
				standardBroadband: response['standard-broadband'],
				fibreBroadband: response['fibre-broadband'],
			});

			document.querySelector('.tariffInformation').style.display = 'block';
			this.searchButton.disabled = false;

		}).catch(e => console.error(e)).finally(() => {
			// this.searchText.innerText = '';
		});
	}
}