import Axios from 'axios';
import qs from 'qs';

const axios = Axios.create({
	baseURL: window.API_URL,
	timeout: 10000,
	transformRequest: (data, headers) => {
		headers.common['Glide-Key'] = window.API_KEY;
		headers.common['X-Requested-With'] = 'XMLHttpRequest';

		if(data) {
			headers.post['Content-Type'] = 'application/json';
			data = JSON.stringify(data);
		}

		return data;
	},
});

export default {
	getTariffs(postcode) {
		return axios.post('tariffs', {
			postcode: postcode,
		}).then(response => {
			return response.data;
		});
	},
	getHistoricTariffs(postcode) {
		return axios.post('tariffs/historic/byArea', {
			postcode: postcode,
		}).then(response => {
			return response.data;
		});
	},
	getQuote(data) {
		return axios.post('signup/quote', data).then(response => {
			return response.data;
		});
	},
	lookupPostcode(postcode) {
		return axios.get('search/postcode', {
			params: {
				postcode,
			},
		}).then(response => {
			return response.data;
		});
	},
	submitMovingHome(data) {
		return axios.post('contact/movingHome', data).then(response => {
			return response.data;
		});
	},
	checkCode(code, postcode) {
		return axios.post('promotion/spin-to-win/code/check', {
			code, postcode,
		}).then(response => {
			return response.data;
		});
	},
	getBroadbandAvailability(house){
		return axios.post('search/getBroadbandAvailability', {
			house : house
		},  {timeout: 20000}).then(response => {
			return response.data;
		});
	},
	submitSatisfactionForm(answers) {
		return axios.post('contact/customer-satisfaction',  {
			data: answers
		}).then(response =>{
			return response.data;
		});
	},
	submitCustomerSurveyForm(answers, code) {
		return axios.post('survey/' + code, {
			survey_answers: answers
		}).then(response => {
			return response;
		});
	},
	checkCoverage(postcode) {
		return axios.get('search/coverage', {
			params: {
				postcode,
			},
		}).then(response => {
			return response.data;
		});
	},
	coverageLocations() {
		return axios.get('search/coverage-locations', {
			params: {
				type: 'joints',
			},
		}).then(response => {
			return response.data;
		});
	}
}
