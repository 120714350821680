import API from './api.js';
import template from './../templates/tariffs.hbs';
import Carousel from "../lib/Carousel";

export default class TariffSearch {
	constructor(element) {
		this.form = element.querySelector('.form');
		this.resultsContainer = element.querySelector('.results');
		this.results = this.resultsContainer.querySelector('.results__list');

		this.tariffs = {};
		this.copy = {
			deemed: null,
			fixed: null,
			limitless: null,
		};

		if(!this.form) {
			throw new TypeError('[TariffSearch] No .form found');
		}

		if(!this.resultsContainer) {
			throw new TypeError('[TariffSearch] No .results found');
		}

		if(!this.results) {
			throw new TypeError('[TariffSearch] No .results__list found');
		}

		this.resultsContainer.style.display = 'none';

		this.initCopy();

		this.form.addEventListener('submit', e => {
			e.preventDefault();
			this.search();
		});
	}

	initCopy() {
		let deemedCopy = this.resultsContainer.querySelector('.deemed-copy'),
		fixedCopy = this.resultsContainer.querySelector('.fixed-copy'),
		limitlessCopy = this.resultsContainer.querySelector('.limitless-copy');

		if(deemedCopy) {
			this.copy.deemed = deemedCopy.innerHTML;
		}

		if(fixedCopy) {
			this.copy.fixed = fixedCopy.innerHTML;
		}

		if (limitlessCopy) {
			this.copy.limitless = limitlessCopy.innerHTML;
		}
	}

	search() {
		let postcode = this.form.elements.postcode.value,
			area = null;

		this.form.search.disabled = true;
		this.form.search.innerText = 'Searching...';

		API.getTariffs(postcode).then(response => {
			this.parseResults(area, response);

			this.resultsContainer.style.display = '';
			this.resultsContainer.scrollIntoView({block: 'start', behavior: 'smooth'});
			this.results.innerHTML = template({
				tariffs: this.tariffs,
				copy: this.copy,
			});

			if(document.querySelector('.carousel') !== null) {
				document.querySelectorAll('.carousel').forEach(el => {
					new Carousel(el, {
						autoFitting: true,
						directions: true,
						progressBar: true
					});
				});
			}

		}).catch(e => console.error(e)).finally(() => {
			this.form.search.disabled = false;
			this.form.search.innerText = 'Search';
		});
	}

	parseResults(areaId, results) {
		this.tariffs = {
			rolling: {
				electricity: null,
				gas: null,
			},
			fixed: {
				electricity: null,
				gas: null,
			},
			variable: {
				electricity: null,
				gas: null,
			},
			limitless: {

			}
		};

		this.tariffs.limitless = results.limitless;

		for(let tariff in results.electricity) {

			if (tariff === 'limitless') {
				continue;
			}

			if(!results.electricity.hasOwnProperty(tariff)) {
				continue;
			}

			if(results.electricity[tariff] && results.electricity[tariff].length) {

				this.tariffs[tariff].electricity = results.electricity[tariff][0];

				this.tariffs[tariff].electricity.tariff_end_date
					= this.getConvertedDate(this.tariffs[tariff].electricity.tariff_end_date);
			}
		}

		for(let tariff in results.gas) {
			if(!results.gas.hasOwnProperty(tariff)) {
				continue;
			}

			if (tariff === 'limitless') {
				continue;
			}

			if(this.tariffs[tariff]) {
				this.tariffs[tariff].gas = results.gas[tariff];

				this.tariffs[tariff].gas.tariff_end_date
					= this.getConvertedDate(this.tariffs[tariff].gas.tariff_end_date);
			}
		}
	}

	getConvertedDate(inputDate) {
		return (new Date(inputDate)).toLocaleString(
			'en-GB',
			{
				dateStyle: 'short'
			}
		);
	}
}
