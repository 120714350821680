import debounce from 'debounce';
import API from './api';

class CustomerSatisfaction {
    constructor(element, opts = {}) {
        this.wrapper = element;
        this.form = this.wrapper.querySelector('.customer-satisfaction-form');

        if(typeof (this.form) === "undefined" || this.form === null) {
            return;
        }

        this.submitButton = this.form.querySelector('.btn');
        this.images = this.form.querySelectorAll('.face');
        this.textFields = this.form.querySelectorAll('.form-text');
        this.radioButtons = this.form.querySelectorAll('.satisfaction-checkbox');
        this.emailBox = null;

        this.formState = {
            q1Rating: '',
            q2Rating: '',
            q3Rating: '',
            q4Checkbox: '',
            q5Text: '',
            email: ''
        };

        let formData = this.formState;

        this.possibleClasses = [
            'very-happy-face',
            'happy-face',
            'neutral-face',
            'sad-face',
            'very-sad-face'
        ];

        this.submitButton.disabled = true;

        this.images.forEach(el => {
            el.addEventListener('click', el => {
                this.updateFaces(el);
                this.validateValues();
            });
        });

        this.textFields.forEach(el => {
           el.addEventListener('input', el => {

               if (el.id === 'email') {
                   this.emailBox = el;
               }

               this.updateTextFields(el.target);
               this.validateValues();
           });
        });

        this.radioButtons.forEach( el => {
           el.addEventListener('change', el => {
                 this.updateCheckBoxes(el.target);
                 this.validateValues();
           });
        });

        this.submitButton.addEventListener('click', function () {
            API.submitSatisfactionForm(formData).then(response => {
                if (response.message) {
                    let errorTag = document.querySelector('.error');
                    errorTag.style.display = 'block';
                    errorTag.innerHTML = response.message;
                } else {
                    window.location.href = "/thank-you-for-your-feedback/";
                }
            });
        });
    }

    validateValues() {
        if (this.formState['q1Rating'] === '' || this.formState['q2Rating'] === '' || this.formState['q3Rating'] === ''
            || this.formState['q4Checkbox'] === '' || this.formState['email'] === '') {
            this.submitButton.disabled = true;
        } else {
            this.submitButton.disabled = false;
        }
    }

    updateFaces(el) {
        let element = document.getElementById(el.target.id);
        let filter = this.possibleClasses.filter((word) => element.classList.contains(word));

        if (element.classList.contains('selected')) {
            element.src = '/wp-content/themes/class/resources/images/customer-satisfaction/' + filter[0] + '.svg';
            element.classList.remove('selected');
        } else {
            element.src = '/wp-content/themes/class/resources/images/customer-satisfaction/' + filter[0] + '-coloured.svg';
            element.classList.add('selected');
        }

        let questionNumber = element.id.substring(0, 2);
        let objectKey = questionNumber + 'Rating';
        this.formState[objectKey] = element.dataset.value;

        this.images.forEach(imageElement => {
            if (imageElement.id.substring(0,2) === questionNumber) {
                if (imageElement.id !== element.id) {
                    imageElement.classList.remove('selected');
                    let imageKey = this.possibleClasses.filter((word) => imageElement.classList.contains(word));
                    imageElement.src = '/wp-content/themes/class/resources/images/customer-satisfaction/' + imageKey[0] + '.svg';
                }
            }
        });
    }

    updateTextFields(element) {

        let emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

        if (element.id === 'email') {
            this.formState["email"] = element.value;

            let matches = element.value.match(emailRegex);

            this.submitButton.disabled = !(element.value.length > 0 && matches !== null);

        } else {
            this.formState[element.id + 'Text'] = element.value;
        }
    }

    updateCheckBoxes(element) {
        this.formState.q4Checkbox = element.value;
    }
}

export default CustomerSatisfaction;