var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"fixed") : stack1)) != null ? lookupProperty(stack1,"electricity") : stack1),{"name":"with","hash":{},"fn":container.program(2, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":4,"column":2},"end":{"line":58,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"col-sm-6 col-bleed-y\">\n			<div class=\"tariff\">\n				<header>\n					<h4>Electricity</h4>\n				</header>\n				<dl class=\"table\">\n					<div>\n						<dt>Supplier</dt>\n						<dd>Glide ("
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"display_name") : stack1), depth0))
    + ")</dd>\n					</div>\n					<div>\n						<dt>Tariff name</dt>\n						<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"customer_tariff_label") : stack1), depth0))
    + "</dd>\n					</div>\n					<div>\n						<dt>Tariff type</dt>\n						<dd>Fixed</dd>\n					</div>\n					<div>\n						<dt>Payment method</dt>\n						<dd>Debit or Credit Card</dd>\n					</div>\n					<div>\n						<dt>Unit rate</dt>\n						<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"g_s_ppu_inc_vat") : stack1), depth0))
    + "</dd>\n					</div>\n					<div>\n						<dt>Standing charge</dt>\n						<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"g_s_sc_inc_vat") : stack1), depth0))
    + "</dd>\n					</div>\n					<div>\n						<dt>Tariff ends on</dt>\n						<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"tariff_end_date") : stack1), depth0))
    + "</dd>\n					</div>\n					<div>\n						<dt>Price guaranteed until</dt>\n						<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"tariff_end_date") : stack1), depth0))
    + "</dd>\n					</div>\n					<div>\n						<dt>Exit fees (If you cancel this tariff before the end date)</dt>\n						<dd>£"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"tariff_exit_fee") : stack1), depth0))
    + "</dd>\n					</div>\n					<div>\n						<dt>Discounts and additional charges</dt>\n						<dd>Not applicable</dd>\n					</div>\n					<div>\n						<dt>Additional products or services included</dt>\n						<dd>Split Billing, Optional Services: Broadband, Water Management, TV Licence Management</dd>\n					</div>\n				</dl>\n			</div>\n		</div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"fixed") : stack1)) != null ? lookupProperty(stack1,"gas") : stack1),{"name":"with","hash":{},"fn":container.program(5, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":62,"column":2},"end":{"line":116,"column":11}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"col-sm-6 col-bleed-y\">\n				<div class=\"tariff\">\n					<header>\n						<h4>Gas</h4>\n					</header>\n					<dl class=\"table\">\n						<div>\n							<dt>Supplier</dt>\n							<dd>Glide ("
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"display_name") : stack1), depth0))
    + ")</dd>\n						</div>\n						<div>\n							<dt>Tariff name</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"customer_tariff_label") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Tariff type</dt>\n							<dd>Fixed</dd>\n						</div>\n						<div>\n							<dt>Payment method</dt>\n							<dd>Debit or Credit Card</dd>\n						</div>\n						<div>\n							<dt>Unit rate</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"unit_price_total_inc_vat") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Standing charge</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"standing_charge_total_inc_vat") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Tariff ends on</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"tariff_end_date") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Price guaranteed until</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"tariff_end_date") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Exit fees (If you cancel this tariff before the end date)</dt>\n							<dd>£"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"tariff_exit_fee") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Discounts and additional charges</dt>\n							<dd>Not applicable</dd>\n						</div>\n						<div>\n							<dt>Additional products or services included</dt>\n							<dd>Split Billing, Optional Services: Broadband, Water Management, TV Licence Management</dd>\n						</div>\n					</dl>\n				</div>\n			</div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"variable") : stack1)) != null ? lookupProperty(stack1,"electricity") : stack1),{"name":"with","hash":{},"fn":container.program(8, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":123,"column":2},"end":{"line":177,"column":11}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"col-sm-6 col-bleed-y\">\n				<div class=\"tariff\">\n					<header>\n						<h4>Electricity</h4>\n					</header>\n					<dl class=\"table\">\n						<div>\n							<dt>Supplier</dt>\n							<dd>Glide ("
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"display_name") : stack1), depth0))
    + ")</dd>\n						</div>\n						<div>\n							<dt>Tariff name</dt>\n							<dd>Lite Tariff</dd>\n						</div>\n						<div>\n							<dt>Tariff type</dt>\n							<dd>Variable</dd>\n						</div>\n						<div>\n							<dt>Payment method</dt>\n							<dd>Debit or Credit Card</dd>\n						</div>\n						<div>\n							<dt>Unit rate</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"g_s_ppu_inc_vat") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Standing charge</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"g_s_sc_inc_vat") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Tariff ends on</dt>\n							<dd>As you are on a variable tariff there is no fixed end date.</dd>\n						</div>\n						<div>\n							<dt>Price guaranteed until</dt>\n							<dd>Not Applicable</dd>\n						</div>\n						<div>\n							<dt>Exit fees (If you cancel this tariff before the end date)</dt>\n							<dd>Not Applicable</dd>\n						</div>\n						<div>\n							<dt>Discounts and additional charges</dt>\n							<dd>Not Applicable</dd>\n						</div>\n						<div>\n							<dt>Additional products or services included</dt>\n							<dd>Not Applicable</dd>\n						</div>\n					</dl>\n				</div>\n			</div>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"variable") : stack1)) != null ? lookupProperty(stack1,"gas") : stack1),{"name":"with","hash":{},"fn":container.program(11, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":181,"column":2},"end":{"line":235,"column":11}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"col-sm-6 col-bleed-y\">\n				<div class=\"tariff\">\n					<header>\n						<h4>Gas</h4>\n					</header>\n					<dl class=\"table\">\n						<div>\n							<dt>Supplier</dt>\n							<dd>Glide ("
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"display_name") : stack1), depth0))
    + ")</dd>\n						</div>\n						<div>\n							<dt>Tariff name</dt>\n							<dd>Lite Tariff</dd>\n						</div>\n						<div>\n							<dt>Tariff type</dt>\n							<dd>Variable</dd>\n						</div>\n						<div>\n							<dt>Payment method</dt>\n							<dd>Debit or Credit Card</dd>\n						</div>\n						<div>\n							<dt>Unit rate</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"unit_price_total_inc_vat") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Standing charge</dt>\n							<dd>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"standing_charge_total_inc_vat") : stack1), depth0))
    + "</dd>\n						</div>\n						<div>\n							<dt>Tariff ends on</dt>\n							<dd>As you are on a variable tariff there is no fixed end date.</dd>\n						</div>\n						<div>\n							<dt>Price guaranteed until</dt>\n							<dd>Not Applicable</dd>\n						</div>\n						<div>\n							<dt>Exit fees (If you cancel this tariff before the end date)</dt>\n							<dd>Not Applicable</dd>\n						</div>\n						<div>\n							<dt>Discounts and additional charges</dt>\n							<dd>Not Applicable</dd>\n						</div>\n						<div>\n							<dt>Additional products or services included</dt>\n							<dd>Not Applicable</dd>\n						</div>\n					</dl>\n				</div>\n			</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        	<div class=\"carousel carousel--products tariff-carousel\">\n                    <ul class=\"carousel__items\" data-carousel=\"items\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"limitless") : stack1),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":245,"column":20},"end":{"line":372,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                    <ul class=\"carousel__nav\" data-carousel=\"navs\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"limitless") : stack1),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":375,"column":20},"end":{"line":377,"column":29}}})) != null ? stack1 : "")
    + "                    </ul>\n                    <ul class=\"carousel__buttons \" data-carousel=\"buttons\">\n                        <li>\n                            <button data-direction=\"prev\" type=\"button\" disabled=\"\">\n                                <span> <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15.6\" height=\"12\">\n                                <path d=\"M5.2 11.7l-4.9-5C.1 6.5 0 6.3 0 6c0-.2.1-.5.3-.7l4.9-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L3.4 5h11.2c.6 0 1 .5 1 1s-.4 1-1 1H3.4l3.2 3.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3s-.5-.1-.7-.3z\"\n                                    fill=\"#fff\"></path></svg> </span></button>\n                        </li>\n                        <li>\n                            <button data-direction=\"next\" type=\"button\">\n                            <span> <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15.6\" height=\"12\">\n                            <path d=\"M9.7 12c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L12.2 7H1c-.6 0-1-.5-1-1s.4-1 1-1h11.2L9 1.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l4.9 5c.2.2.3.5.3.7 0 .3-.1.5-.3.7l-4.9 5c-.2.2-.4.3-.7.3z\"\n                                fill=\"#fff\"></path></svg> </span></button>\n                        </li>\n                    </ul>\n            <div class=\"carousel__progress\" style=\"margin: 1rem 0 6rem;\" data-carousel=\"progress\"><span></span></div>\n        </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"carousel__item\" style=\"flex: 0 0 100rem; box-shadow: none; padding: 0 2rem;\">\n                            <div class=\"carousel-tariff-container grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"gas") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":248,"column":32},"end":{"line":308,"column":36}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"electricity") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":309,"column":29},"end":{"line":369,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                        </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"electricity") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":249,"column":36},"end":{"line":253,"column":43}}})) != null ? stack1 : "")
    + "                                        <header>\n                                            <h4>Gas</h4>\n                                        </header>\n                                        <dl class=\"table\">\n                                            <div>\n                                                <dt>Supplier</dt>\n                                                <dd>Glide ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gas") : depth0)) != null ? lookupProperty(stack1,"display_name") : stack1), depth0))
    + ")</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Tariff name</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gas") : depth0)) != null ? lookupProperty(stack1,"customer_tariff_label") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Tariff type</dt>\n                                                <dd>Fixed</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Payment method</dt>\n                                                <dd>Debit or Credit Card</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Unit rate</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gas") : depth0)) != null ? lookupProperty(stack1,"unit_price_total_inc_vat") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Standing charge</dt>\n                                                <dd>£"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gas") : depth0)) != null ? lookupProperty(stack1,"standing_charge_total_inc_vat") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Tariff ends on</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gas") : depth0)) != null ? lookupProperty(stack1,"tariff_end_date") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Price guaranteed until</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gas") : depth0)) != null ? lookupProperty(stack1,"tariff_end_date") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Exit fees (If you cancel this tariff before the end date)</dt>\n                                                <dd>£"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gas") : depth0)) != null ? lookupProperty(stack1,"tariff_exit_fee") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Capacity</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gas") : depth0)) != null ? lookupProperty(stack1,"capacity") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Discounts and additional charges</dt>\n                                                <dd>Not applicable</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Additional products or services included</dt>\n                                                <dd>Split Billing, Optional Services: Broadband, Water Management, TV Licence Management</dd>\n                                            </div>\n                                        </dl>\n                                    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                    <div class=\"tariff tariff-left col-sm-6\" style=\"padding-right: 10px;\">\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                                    <div class=\"tariff col-12\" style=\"word-spacing: 0 !important;\">\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"gas") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":310,"column":36},"end":{"line":314,"column":43}}})) != null ? stack1 : "")
    + "                                        <header>\n                                            <h4>Electricity</h4>\n                                        </header>\n                                        <dl class=\"table\">\n                                            <div>\n                                                <dt>Supplier</dt>\n                                                <dd>Glide ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"electricity") : depth0)) != null ? lookupProperty(stack1,"display_name") : stack1), depth0))
    + ")</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Tariff name</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"electricity") : depth0)) != null ? lookupProperty(stack1,"customer_tariff_label") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Tariff type</dt>\n                                                <dd>Fixed</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Payment method</dt>\n                                                <dd>Debit or Credit Card</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Unit rate</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"electricity") : depth0)) != null ? lookupProperty(stack1,"g_s_ppu") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Standing charge</dt>\n                                                <dd>£"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"electricity") : depth0)) != null ? lookupProperty(stack1,"g_s_sc_inc_vat") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Tariff ends on</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"electricity") : depth0)) != null ? lookupProperty(stack1,"tariff_end_date") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Price guaranteed until</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"electricity") : depth0)) != null ? lookupProperty(stack1,"tariff_end_date") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Exit fees (If you cancel this tariff before the end date)</dt>\n                                                <dd>£"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"electricity") : depth0)) != null ? lookupProperty(stack1,"tariff_exit_fee") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Capacity</dt>\n                                                <dd>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"electricity") : depth0)) != null ? lookupProperty(stack1,"capacity") : stack1), depth0))
    + "</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Discounts and additional charges</dt>\n                                                <dd>Not applicable</dd>\n                                            </div>\n                                            <div>\n                                                <dt>Additional products or services included</dt>\n                                                <dd>Split Billing, Optional Services: Broadband, Water Management, TV Licence Management</dd>\n                                            </div>\n                                        </dl>\n                                    </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                                    <div class=\"tariff col-sm-6\">\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                                    <div class=\"tariff\" style=\"word-spacing: unset;\">\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "                        <li></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"copy") : depth0)) != null ? lookupProperty(stack1,"fixed") : stack1), depth0)) != null ? stack1 : "")
    + "\n<div class=\"grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"fixed") : stack1)) != null ? lookupProperty(stack1,"electricity") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":1},"end":{"line":59,"column":8}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"fixed") : stack1)) != null ? lookupProperty(stack1,"gas") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":61,"column":1},"end":{"line":117,"column":8}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"copy") : depth0)) != null ? lookupProperty(stack1,"deemed") : stack1), depth0)) != null ? stack1 : "")
    + "\n<div class=\"grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"variable") : stack1)) != null ? lookupProperty(stack1,"electricity") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":122,"column":1},"end":{"line":178,"column":8}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"variable") : stack1)) != null ? lookupProperty(stack1,"gas") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":180,"column":1},"end":{"line":236,"column":8}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"copy") : depth0)) != null ? lookupProperty(stack1,"limitless") : stack1), depth0)) != null ? stack1 : "")
    + "\n\n<div class=\"grid\" style=\"overflow-x: hidden; word-spacing: normal;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"limitless") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":242,"column":5},"end":{"line":395,"column":15}}})) != null ? stack1 : "")
    + "</div>\n\n<p><a href=\"/historic/"
    + container.escapeExpression(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"tariffs") : depth0)) != null ? lookupProperty(stack1,"fixed") : stack1)) != null ? lookupProperty(stack1,"electricity") : stack1)) != null ? lookupProperty(stack1,"area") : stack1), depth0))
    + "\">Don't see your tariff above? Check out our historic tariffs here.</a></p>\n";
},"useData":true,"useBlockParams":true});