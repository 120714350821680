class Contact {
	constructor(element) {
		this.wrapper = element;
		this.blocks = this.wrapper.querySelectorAll('button.block');

		this.blocks.forEach(block => {
			block.addEventListener('click', e => {
				this.setActive(block);
				e.preventDefault();
			});
		});
	}

	setActive(block) {
		let active = this.wrapper.querySelector('.block--active');

		if(active) {
			let activeContent = this.wrapper.querySelector(`#${active.id}-content`);
			active.classList.remove('block--active');

			if(activeContent) {
				activeContent.style.display = 'none';
			}
		}

		let content = this.wrapper.querySelector(`#${block.id}-content`);
		block.classList.add('block--active');

		if(content) {
			content.style.display = 'block';
			content.scrollIntoView({block: 'start', behavior: 'smooth'});
		}
	}
}

export default Contact;
