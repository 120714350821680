import Choices from 'choices.js';
import TariffSearch from './TariffSearch';
import BroadbandSearch from './BroadbandSearch';
import PostcodeLookup from './PostcodeLookup';
import CustomerSatisfaction from "./CustomerSatisfaction";
import CoverageChecker from "./CoverageChecker";
import API from './api';

export default {
	init() {
		if(document.querySelector('select') !== null) {
			document.querySelectorAll('select').forEach(el => {

				let config = {
					searchEnabled: false,
					searchChoices: false,
					itemSelectText: '',
				};

				if (el.classList.contains('do-not-sort')) {
					config.shouldSort = false;
				}

				el.choices = new Choices(el, config);
			});
		}

		if(document.querySelector('.section--tariff-search') !== null) {
			document.querySelectorAll('.section--tariff-search').forEach(el => {
				el.tariffSearch = new TariffSearch(el);
			});
		}

		if(document.querySelector('.section--broadband-search') !== null) {
			document.querySelectorAll('.section--broadband-search').forEach(el => {
				el.tariffSearch = new BroadbandSearch(el);
			});
		}

		if(document.querySelector('.postcode-lookup') !== null) {
			document.querySelectorAll('.postcode-lookup').forEach(el => {
				el.postcodeLookup = new PostcodeLookup(el);
			});
		}

		if(document.querySelector('.section--customer-satisfaction') !== null) {
			document.querySelectorAll('.section--customer-satisfaction').forEach(el => {
				el.customerSatisfaction = new CustomerSatisfaction(el);
			});
		}

		if(document.querySelector('.section--coverage-checker') !== null) {
			document.querySelectorAll('.section--coverage-checker').forEach(el => {
				el.coverageChecker = new CoverageChecker(el);
			});
		}

		if(window.Parsley) {
			this.initParsley();
		}
	},

	initParsley() {
		//Add standard class to caldera form validation and fix errorContainer to handle class change
		window.Parsley.on('form:init', form => {
			form.options.errorTemplate = '<span></span>';
			form.options.errorsWrapper = '<span class="form__validation help-block caldera_ajax_error_block"></span>';
			form.options.errorWrapper = '<span></span>';
			form.options.errorsContainer = field => field.$element.closest('.form__group');
		});

		window.Parsley.on('field:success', field => {
			field.element.classList.remove('form__control--invalid');
			field.element.classList.add('form__control--valid');

			let formGroup = field.element.closest('.form__group');

			if(formGroup) {
				formGroup.classList.remove('form__group--invalid');
				formGroup.classList.add('form__group--valid');
			}
		});

		window.Parsley.on('field:error', field => {
			field.element.classList.remove('form__control--valid');
			field.element.classList.add('form__control--invalid');

			let formGroup = field.element.closest('.form__group');

			if(formGroup) {
				formGroup.classList.add('form__group--invalid');
				formGroup.classList.remove('form__group--valid');
			}
		});
	},
}
