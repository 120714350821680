import { jarallax } from 'jarallax';

export default {
	init() {
		if(document.querySelector('#nav-trigger') !== null){
			document.querySelectorAll('#nav-trigger').forEach(el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					document.body.classList.toggle('menu-open');
				});
			});
		}

		if(document.querySelectorAll('.jarallax') !== null){
			if(window.outerWidth > 767){
				jarallax(document.querySelectorAll('.jarallax'), {
					speed: 0.5,
					imgSize: 'contain',
					imgPosition: 'center right',
					onInit: function(){
						this.image.$container.classList.add('animated', 'fade-in-up', 'duration--24', 'delay--4');
					}
				});
			} else {
				// document.querySelectorAll('.jarallax').forEach(el => {
				// 	if(typeof el.dataset.mobileBg !== 'undefined'){
				// 		el.style.backgroundImage = 'url(' + el.dataset.mobileBg + ')';
				// 	}
				// });
			}
		}
	}
}
