import Animations from './Animations';

class InfiniteScroll {
	constructor(element, opts = {}) {
		this.wrapper = element;

		this.grid = document.querySelector('[data-grid]');
		this.button = document.querySelector('[data-load-more]');

		this.page 	  = (typeof this.wrapper.dataset.nextPage !== 'undefined') ? parseInt(this.wrapper.dataset.nextPage) : 2;
		this.urlBase  = (typeof this.wrapper.dataset.url !== 'undefined') 	   ? this.wrapper.dataset.url : null;
		this.maxPages = (typeof this.wrapper.dataset.maxPages !== 'undefined') ? parseInt(this.wrapper.dataset.maxPages) : false;

		if(this.grid !== null && this.urlBase !== null){
			if(this.button === null){
				console.warn('No [data-load-more] found.');
			} else {

				this.button.style.display = 'inline-block';
				this.button.label = this.button.innerText;
			}

			this.addEventListeners();
		} else {
			if(this.grid === null){
				console.error('No [data-grid] found. Exiting.');
			}
			if(this.urlBase === null){
				console.error('URL Base not defined. Exiting.');
			}
		}
	}

	addEventListeners(){
		if(this.button !== null){
			this.button.addEventListener('click', this.loadMore.bind(this));
		}
	}

	loadMore(ev){
		ev.preventDefault();

		this.button.innerText = 'Loading...';
		this.button.style.opacity = 0.5;

		try {
			const data = this.postData(this.urlBase + 'page/' + this.page + '/');

			data.then(resp => resp.text())
				.then(html => {// Initialize the DOM parser
					const parser = new DOMParser();
					const doc = parser.parseFromString(html, "text/html");

					if(doc.body.querySelector('.inf-scroll__item') !== null){
						doc.body.querySelectorAll('.inf-scroll__item').forEach(el =>{
							this.grid.appendChild(el);
							Animations.initAnimation(el);
						});
					}

					this.page++;
					this.wrapper.dataset.nextPage = this.page;

					if(this.maxPages < this.page){
						this.button.style.opacity = 0;
					} else {
						this.button.innerText = this.button.label;
						this.button.style.opacity = 1;
					}
				})
				.catch(err => {
					console.error(err);
					// this.button.disabled = false;

					this.button.innerText = this.button.label;
					this.button.style.opacity = 1;
				});
		} catch (error) {
			console.error(error);

			this.button.innerText = this.button.label;
			this.button.style.opacity = 1;
		}
	}

	async postData(url = '') {
		const response = await fetch(url, {
			method: 'GET', // *GET, POST, PUT, DELETE, etc.
			mode: 'same-origin', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'text/html',
				'X-Requested-With' : 'xmlhttprequest',
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			redirect: 'follow', // manual, *follow, error
			referrer: 'no-referrer', // no-referrer, *client
		});

		return await response; // parses JSON response into native JavaScript objects
	}
}

export default InfiniteScroll;
