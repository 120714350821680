class Carousel {

	constructor(element, opts = {}) {
		this.directions = opts.directions || false;
		this.progressBar = opts.progressBar || false;
		this.navigation = opts.navigation || true;
		this.endless = opts.endless || false;
		this.autoFitting = opts.autoFitting || false;

		this.isSafari = /.*Version.*Safari.*/.test(navigator.userAgent);

		this.element = element;
		this.links = [...this.element.querySelectorAll('a')];
		this.list = this.element.querySelector('[data-carousel="items"]');
		this.items = [...this.element.querySelectorAll('[data-carousel="items"] .carousel__item')];

		if(this.items.length === 1) {
			this.navigation = false
		}

		if (this.navigation) {
			this.navs = [...this.element.querySelectorAll('[data-carousel="navs"] > *')]
		}

		if (this.directions) {
			this.buttons = [...this.element.querySelectorAll('[data-carousel="buttons"] button')]
		}

		if (this.progressBar) {
			this.progressSelector = this.element.querySelector('[data-carousel="progress"] > *')
		}

		this.dragged = false;
		this.current = 0;
		this.href = '';

		this.startPoint = 0;
		this.currentPoint = 0;
		this.lastPoint = 0;
		this.progress = 0;

		this.startTime = null;
		this.leftTime = null;
		this.timeBreakpoint = 150;

		this.init()

	}

	init() {

		this.reset();

		this.items.forEach((item) => {
			item.addEventListener('mousedown', () => {
				this.href = item.dataset.href ? item.dataset.href : ''
			})
		});

		this.list.addEventListener('mouseleave', () => {
			this.dragged = false
		});

		// EVENTS
		this.list.addEventListener('mousedown', (e) => {
			this.onMousedown(e)
		});

		this.list.addEventListener('mousemove', (e) => {
			this.onMousemove(e)
		});

		this.list.addEventListener('mouseup', () => {
			this.onMouseup()
		});

		this.list.addEventListener('touchstart', (e) => {
			this.onMousedown(e)
		}, {passive: true});

		this.list.addEventListener('touchmove', (e) => {
			this.onMousemove(e)
		}, {passive: true});

		this.list.addEventListener('touchend', () => {
			this.onMouseup()
		});

		if (this.links.length > 0) {
			this.links.forEach((link) => {
				link.addEventListener('click', (e) => {
					e.preventDefault()
				})
			})
		}

		window.addEventListener('resize', () => {
			this.initWidth();
			this.getSlide();
			if(this.navigation) {
				this.getNavClasses()
			}

		});

		if (this.navigation) {

			this.navs.forEach((nav, index) => {
				nav.addEventListener('click', () => {
					this.current = index;
					this.getSlide()
				})
			})
		}

	}

	reset() {

		this.initWidth();
		this.initStyles();

		if (this.directions) this.initButtons();
		if (this.progressBar) this.getProgressBar();
		if (this.directions) this.getButtonsStyles()

	}

	initStyles() {
		this.items[this.current].classList.add('is-active');
		if(this.navigation) {
			this.navs[this.current].classList.add('is-active')
		}
		this.getClasses()
	}

	initButtons() {
		this.buttons.forEach((btn) => {
			btn.addEventListener('click', () => {
				let dir = btn.dataset.direction;
				this.getNextSlide(dir)
			})
		})
	}

	onMousedown(e) {

		this.dragged = true;
		this.startPoint = e.clientX || e.touches[0].clientX;
		this.currentPoint = this.startPoint;
		this.startTime = new Date().getTime();
		this.list.classList.remove('is-moving');
		this.list.classList.add('is-dragging')

	}

	onMousemove(e) {

		if (this.dragged) {

			this.currentPoint = e.clientX || e.touches[0].clientX;

			let distance = this.currentPoint - this.startPoint;
			let diff = this.lastPoint + distance;

			if (diff > 0) {
				diff = 0
			} else if (diff <= this.rightEdge) {
				diff = this.rightEdge
			}

			this.list.style.transform = `matrix(1, 0, 0, 1, ${diff}, 0)`

		}
	}

	onMouseup() {

		this.dragged = false;

		this.list.classList.remove('is-dragging');

		let distance = this.currentPoint - this.startPoint;

		this.lastPoint = this.lastPoint + distance;

		if (this.lastPoint > 0) {
			this.lastPoint = 0
		} else if (this.lastPoint < this.rightEdge) {
			this.lastPoint = this.rightEdge
		}

		this.leftTime = new Date().getTime();

		let timeDiff = this.leftTime - this.startTime;

		let positiveDist = Math.abs(distance);

		let dir = distance > 0 ? 'prev' : 'next';

		if (positiveDist <= 10 && timeDiff <= this.timeBreakpoint && this.href !== '') {
			window.location.href = this.href
		}

		if (timeDiff <= this.timeBreakpoint && positiveDist > 20) {
			this.getNextSlide(dir)

		} else if (timeDiff > this.timeBreakpoint && positiveDist < this.items[0].offsetWidth && positiveDist > 30 ) {

			this.current = dir === 'prev' ? this.current - 1 : this.current + 1;

			if(this.current < 0) {
				this.current = 0
			} else if (this.current === this.items.length) {
				this.current = this.items.length - 1
			}

			this.getSlide()

		} else {

			this.current = Math.abs(Math.round(this.lastPoint / this.items[0].offsetWidth));

			if (this.current > this.items.length - 1) {
				this.current = this.items.length - 1
			}

			if (this.autoFitting) {
				this.getSlide()
			} else {
				if(this.navigation) {
					this.getNavClasses()
				}
			}
		}

		this.href = ''

	}

	getNextSlide(dir) {

		let next;
		let len = this.items.length - 1;

		if (dir === 'next') {
			next = this.current + 1
		} else {
			next = this.current - 1
		}

		if (next > len) {
			next = this.endless ? 0 : len
		} else if (next < 0) {
			next = this.endless ? len : 0
		}

		this.current = next;

		this.getSlide()

	}

	getSlide(action) {

		if (!this.dragged) {

			let finalPoint = -1*(this.items[this.current].offsetLeft);

			if(this.isSafari) {
				finalPoint = finalPoint + this.list.offsetLeft
			}

			finalPoint = finalPoint > this.rightEdge ? finalPoint : this.rightEdge;
			finalPoint = finalPoint > 0 ? 0 : finalPoint;

			if (action !== 'resize') {
				this.list.classList.add('is-moving')
			}

			if (this.navigation) {
				this.getNavClasses()
			}

			this.items.forEach((item) => {
				item.classList.remove('is-active');
				item.classList.remove('is-left');
				item.classList.remove('is-right')
			});

			this.getClasses();

			this.items[this.current].classList.add('is-active');

			this.list.style.transform = `matrix(1, 0, 0, 1, ${finalPoint}, 0)`;

			this.lastPoint = finalPoint;

			if (this.progressBar) this.getProgressBar();
			if (this.directions) this.getButtonsStyles()

		}

	}

	getNavClasses() {
		this.navs.forEach((nav) => {
			nav.classList.remove('is-active')
		});
		this.navs[this.current].classList.add('is-active')
	}

	getClasses() {
		for (let i = 0; i < this.current; i++) {
			this.items[i].classList.add('is-left')
		}
		for (let i = this.current + 1; i < this.items.length; i++) {
			this.items[i].classList.add('is-right')
		}
	}

	initWidth() {
		let last = this.items[this.items.length - 1];

		this.innerWidth = last.offsetLeft + last.offsetWidth;

		if (this.innerWidth > this.list.offsetWidth) {
			this.getRightEdge()
		} else {
			this.rightEdge = 0
		}

	}

	getRightEdge() {

	 	let offsetLeft = 0;

		if(this.isSafari) {
			offsetLeft = this.list.offsetLeft
		}

		this.rightEdge = (this.innerWidth - (this.list.offsetWidth + offsetLeft)) * -1

	}

	getProgressBar() {

		let perc = Math.abs(this.lastPoint/this.rightEdge);

		if (perc === 0) {
			perc = (1/this.items.length).toFixed(2)
		} else {
			perc = perc.toFixed(2)
		}

		this.progress = this.lastPoint === 0 ? 0 : parseFloat(perc);

		this.progressSelector.style.transform = `matrix(${perc}, 0, 0, 1, 0, 0)`
	}

	getButtonsStyles() {
		if (this.progress === 0) {
			this.buttons[0].disabled = true;
			this.buttons[1].disabled = false;
		} else if (this.progress === 1) {
			this.buttons[0].disabled = false;
			this.buttons[1].disabled = true;
		} else {
			this.buttons.forEach((btn) => {
				btn.disabled = false;
			})
		}
	}

}

export default Carousel
