export const $ = {
	selector: _selector,
	selectors: _selectors,
	expand: _expand,
	scrollTo: _scrollTo
}

function _selector(el) {
	return document.querySelector(el)
}

function _selectors(els) {
	return [...document.querySelectorAll(els)]
}

function _expand(el, opts = {}) {
	let params = {
		duration: opts.duration || 200,
		direction: opts.direction || 'toggle', // 'close, open, toggle'
		scrollHeight: el.scrollHeight,
		currentHeight: el.clientHeight,
		opacity: opts.opacity || false
	}

	if (params.scrollHeight === 0) {
		el.style.display = 'block'
		params.scrollHeight = el.scrollHeight
		el.style.display = 'none'
	}

	if (params.direction === 'toggle') {

		if (params.currentHeight === 0) {
			_expandOpen(el, params)
		} else {
			_expandClose(el, params)
		}

	} else if (params.direction === 'close' && params.currentHeight !== 0) {
		_expandClose(el, params)
	} else if (params.direction === 'open' && params.currentHeight === 0) {
		_expandOpen(el, params)
	}

}

function _expandOpen(el, params) {
	const s = el.style
	let px = 0
	let pxFrame = params.scrollHeight/(params.duration/(1000/60))
	let progress = 0

	s.display = 'block'
	s.height = 0 + 'px'
	s.overflow = 'hidden'
	setTimeout(()=>{
		el.classList.add('is-expanded')
	}, 10)

	if (params.opacity) {
		s.opacity = 0
	}

	let expanding = (dist) => {
		
		px += dist
		s.height = px + 'px'
		s.height = px >= params.scrollHeight ? params.scrollHeight + 'px' : px + 'px'

		progress = Math.round((100/params.scrollHeight) * px)/100

		if (params.opacity) {
			s.opacity = progress
		}

		if (px >= params.scrollHeight) {
			cancelAnimationFrame(expanding)
			s.height = '' // IE support
			s.height = null
			s.overflow = '' // IE support
			s.overflow = null 
			s.opacity = ''
			s.opacity = null
		} else {
			requestAnimationFrame(()=>{
				expanding(dist)
			})
		}

	}

	requestAnimationFrame(()=>{
		expanding(pxFrame)
	})

}

function _expandClose(el, params) {
	const s = el.style
	let px = params.currentHeight
	let pxFrame = params.currentHeight/(params.duration/(1000/60))

	let progress = 1
	
	s.height = px + 'px'
	s.overflow = 'hidden'
	el.classList.remove('is-expanded')

	if (params.opacity) {
		s.opacity = 1
	}

	let collapsing = (dist) => {

		px -= dist
		s.height = px < 0 ? 0 : px + 'px'

		progress = Math.round((100/params.scrollHeight) * px)/100

		if (params.opacity) {
			s.opacity = progress
		}

		if (px < 0) {
			setTimeout(() => {
				cancelAnimationFrame(collapsing)
				s.display = 'none'
				s.height = '' // IE support
				s.height = null
				s.overflow = '' // IE support
				s.overflow = null
				s.opacity = ''
				s.opacity = null
			}, 10)
		} else {
			requestAnimationFrame(() => {
				collapsing(dist)
			})
		}

	}

	requestAnimationFrame(()=>{
		collapsing(pxFrame)
	})

}

function _scrollTo(destination, duration = 200, easing = 'linear', fromTop = 0, callback) {

  const easings = {
    linear(t) {
      return t
    },
    easeInQuad(t) {
      return t * t
    },
    easeOutQuad(t) {
      return t * (2 - t)
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    },
    easeInCubic(t) {
      return t * t * t
    },
    easeOutCubic(t) {
      return (--t) * t * t + 1
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
    easeInQuart(t) {
      return t * t * t * t
    },
    easeOutQuart(t) {
      return 1 - (--t) * t * t * t
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t
    },
    easeInQuint(t) {
      return t * t * t * t * t
    },
    easeOutQuint(t) {
      return 1 + (--t) * t * t * t * t
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
    }
  }

  const start = window.pageYOffset
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime()

  const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight)
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight
  const destinationOffset = typeof destination === 'number' ? destination : destination.getBoundingClientRect().top + window.scrollY
  const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset - fromTop)


  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll)
    if (callback) {
      callback()
    }
    return
  }

  function scroll() {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime()
    const time = Math.min(1, ((now - startTime) / duration))
    const timeFunction = easings[easing](time)
    window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start))

    if (window.pageYOffset === destinationOffsetToScroll || (now - startTime) >= duration) {
      if (callback) {
        callback()
      }
      return
    }

    requestAnimationFrame(scroll)
  }

  scroll()

}
