class Tabslider {

	constructor(element, opts = {}) {
		this.element = element;

		this.tabbed = opts.tabbed || false;

		if (this.tabbed) {
			this.tabs = [...this.element.querySelectorAll('[data-slider="tabs"] > *')]
		}

		this.navs = [...this.element.querySelectorAll('[data-slider="navs"] [data-dir]')];

		this.images = [...this.element.querySelectorAll('[data-slider="image"]')];

		this.view = this.element.querySelector('[data-slider="view"]');
		this.content = this.element.querySelector('[data-slider="content"]');

		this.contents = [...this.element.querySelectorAll('[data-slider="content"] > *')];

		this.current = 0;

		this.breakpoint = window.matchMedia('(max-width:' + (opts.breakpoint || 768) + 'px)');

		this.timeouts = [];

		this.animate = opts.animate || false;

		// Time between nav clicks for better UX
		// ---------------------------
		this.oldClick = new Date().getTime();
		this.newClick = new Date().getTime() + (10 * 1000);

		// ---------------------------

		// Swipe properties
		// ----------------------
		this.isSwipe = opts.isSwipe || false;
		this.swipeEnter = 0;
		this.swipeLeave = 0;
		this.swipeStart = null;
		this.swipeEnd = null;
		// ----------------------


		// Height array
		// -------------------
		this.heights = [];
		this.height = 0;
		// -------------------

		this.init()
	}

	init() {

		this.getHeight();

		this.getTab(this.tabbed ? this.tabs[0] : null, 0);

		if (this.animate) {
			this.initAnimation()
		}

		if (this.tabbed) {
			this.tabs.forEach((tab, index) => {
				tab.addEventListener('click', () => {

					this.newClick = new Date().getTime();

					if (index !== this.current) {
						this.getTab(tab, index)
					}

					this.oldClick = this.newClick

				})
			})
		}

		this.navs.forEach((nav) => {
			nav.addEventListener('mousedown', (e) => {
				e.preventDefault()
			});

			nav.addEventListener('click', () => {

				this.newClick = new Date().getTime();

				this.getNextTab(nav.dataset.dir);

				this.oldClick = this.newClick

			})

		});

		// Animation
		// ------------------------------
		this.element.addEventListener('mouseover', () => {
			if (this.animate) {
				clearInterval(this.animation)
			}
		});

		this.element.addEventListener('mouseleave', () => {
			if (this.animate) {
				this.initAnimation()
			}
		});
		// ------------------------------

		window.addEventListener('resize', () => {
			this.getHeight();
			if (this.animate) {
				this.initAnimation()
			}
		});

		// Swipe events
		// --------------------
		// this.contents.forEach((content) => {

		if(this.tabs.length > 1 && this.view) {

			this.view.addEventListener('mousedown', (e) => {
				e.preventDefault();
				this.onMousedown(e)
			});
			this.view.addEventListener('mousemove', (e) => {
				this.onMousemove(e)
			});
			this.view.addEventListener('mouseup', () => {
				this.onMouseup()
			});
			this.view.addEventListener('touchstart', (e) => {
				this.onMousedown(e)
			});

			this.view.addEventListener('touchmove', (e) => {
				this.onMousemove(e)
			});

			this.view.addEventListener('touchend', () => {
				this.onMouseup()
			})

		}

		// })
		// --------------------

	}

	getHeight(){
		this.contents.forEach((content) => {
			content.style.display = 'block';
			this.heights.push(content.scrollHeight);
			content.setAttribute('style', '')
		});

		this.height = Math.max(...this.heights);

		this.heights = [];


		this.content.style.height = `${this.height}px`
	}

	onMousedown(e) {
		this.swipeEnter = e.clientX || e.touches[0].clientX;
		this.swipeLeave = e.clientX || e.touches[0].clientX;
		this.swipeStart = new Date().getTime()
	}

	onMousemove(e) {
		this.swipeLeave = e.clientX || e.touches[0].clientX;
		this.swipeEnd = new Date().getTime()
	}

	onMouseup() {
		let distance = this.swipeLeave - this.swipeEnter;
		let diff = this.swipeEnd - this.swipeStart;

		let dir = distance > 0 ? 'prev' : 'next';

		distance = Math.abs(distance);

		if (diff <= 320 && distance > 50 && this.breakpoint.matches) {
			this.getNextTab(dir)
		}
	}

	getTab(tab, index) {
		this.current = index;
		if (this.tabbed) this.resetTabs();

		this.updateImages();

		if (this.tabbed && this.tabs.length > 1) tab.classList.add('is-active');
		this.contentActiveClass()
	}

	contentActiveClass() {
		this.contents[this.current].classList.add('is-visible');
		setTimeout(()=>{
			this.contents[this.current].classList.add('is-active')
		}, 100)
	}

	getNextTab(dir) {
		const last = this.contents.length - 1;
		let next;

		if (dir === 'next') {
			next = this.current + 1
		} else {
			next = this.current - 1
		}

		if (next > last) {
			next = 0
		} else if (next < 0) {
			next = last
		}

		this.current = next;

		if (this.tabbed) this.resetTabs();
		if (this.tabbed) this.tabs[this.current].classList.add('is-active');

		if (this.newClick - this.oldClick > 1000) {
			this.updateImages()
		} else {
			this.pauseImages()
		}

		this.contentActiveClass()
	}

	resetTabs() {
		this.tabs.forEach((tab) => {
			tab.classList.remove('is-active')
		});
		this.contents.forEach((content) => {
			content.classList.remove('is-visible');
			content.classList.remove('is-active')
		})
	}

	pauseImages() {

		this.clearAllTimeouts();

		this.images.forEach(() => {

			let update = setTimeout(() => {
				this.updateImages()
			}, 250);

			this.timeouts.push(update)

		})
	}

	updateImages() {

		this.clearAllTimeouts();

		this.images.forEach((img, i) => {

			let timeout = setTimeout(() => {

				let currentImg = () => {

					const assets = [...img.querySelectorAll('img')];

					let removeImgClass = () => {
						this.removeImageClasses(img)
					};

					if (assets.length > 0) {

						assets.forEach((asset) => {
							asset.classList.remove('is-active')
						});

						assets[this.current].classList.add('is-active')
					}

					img.classList.add('is-leaving');

					let timeoutTwo = setTimeout(removeImgClass, 200);
					this.timeouts.push(timeoutTwo)

				};

				let timeoutOne = setTimeout(currentImg, 300);
				this.timeouts.push(timeoutOne);
				img.classList.add('is-updating')

			}, 150 * i);

			this.removeImageClasses(img);
			this.timeouts.push(timeout)

		})
	}

	clearAllTimeouts() {
		this.timeouts.forEach((timeout) => clearTimeout(timeout));
		this.timeouts = []
	}

	removeImageClasses(img) {
		img.classList.remove('is-leaving');
		img.classList.remove('is-updating')
	}

	initAnimation() {

		if (this.animation) clearInterval(this.animation);

		if (!this.breakpoint.matches) {
			this.animation = setInterval(() => {
				this.startAnimation()}
			, 5000)
		}
	}

	startAnimation() {
		this.getNextTab('next')
	}

}

export default Tabslider
