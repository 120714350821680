import Carousel from '../lib/Carousel';
import Tabslider from '../lib/TabSlider';

export default {
	init() {
		if(document.querySelectorAll('.carousel--products, .carousel--images') !== null){
			document.querySelectorAll('.carousel--products, .carousel--images').forEach(element => {
				new Carousel(element, {
					directions: true,
					progressBar: true,
					navigation: false,
					autoFitting: true
				});
			});
		}

		if(document.querySelectorAll('.carousel') !== null){
			document.querySelectorAll('.carousel').forEach(element => {
				new Carousel(element, {
					autoFitting: true
				});
			});
		}

		if(document.querySelectorAll('.section--case-studies') !== null){
			document.querySelectorAll('.section--case-studies').forEach(element => {
				new Tabslider(element, {
					breakpoint: 1024,
					tabbed: true
				})
			});
		}
	}
}
