import $ from "jquery";

import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
// import 'lazysizes';
import 'fslightbox';
import 'scopedQuerySelectorShim';

import Affix from './modules/affix';
import Accordion from './lib/Accordion';
import Calculator from './modules/Calculator';
import InfiniteScroll from './modules/InfiniteScroll';
import Sliders from './modules/Sliders';
import StaggeredGrid from './modules/StaggeredGrid';
import Header from './modules/Header';
import Forms from './modules/Forms';
import Animations from './modules/Animations';
import Contact from './modules/Contact';

window.glide = {
	init: function() {
		if(typeof NodeList.prototype.forEach !== 'function') {
			NodeList.prototype.forEach = Array.prototype.forEach;
		}

		if(typeof HTMLCollection.prototype.forEach !== 'function') {
			HTMLCollection.prototype.forEach = Array.prototype.forEach;
		}

		Header.init();
		Affix.init();
		Sliders.init();
		StaggeredGrid.init();
		Forms.init();

		this.Forms = Forms;

		Animations.initAnimations(document.body);

		if(document.querySelector('.section--calculator') !== null) {
			document.querySelectorAll('.section--calculator').forEach(el => {
				el.calculator = new Calculator(el);
			});
		}

		if(document.querySelector('.inf-scroll') !== null) {
			document.querySelectorAll('.inf-scroll').forEach(el => {
				el.infiniteScroll = new InfiniteScroll(el);
			});
		}

		if(document.querySelector('.accordion') !== null) {
			document.querySelectorAll('.accordion').forEach(element => {
				new Accordion(element, {
					active: element.dataset.active,
					collapse: true,
				});
			});
		}

		if(document.querySelector('.section--locations .grid--locations') !== null) {
			document.querySelectorAll('.section--locations .grid--locations').forEach(element => {
				new Accordion(element, {
					collapse: true,
					opacity: false,
					breakpoint: 767,
					selector: '.location',
				});
			});
		}

		if(document.querySelector('.section--contact') !== null) {
			document.querySelectorAll('.section--contact').forEach(element => {
				element.contact = new Contact(element);
			});
		}

		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});

		//Hack for zero risk bills header buttons
		if(document.querySelector('.buttons .btn') !== null) {
			document.querySelectorAll('.buttons .btn').forEach(button => {
				button.addEventListener('click', e => {
					let block = null;

					if(button.hash === '#block-1-content') {
						block = document.getElementById('block-1');
					} else if(button.hash === '#block-3-content') {
						block = document.getElementById('block-3');
					}

					if(block) {
						block.click();
						e.preventDefault();
					}
				})
			});
		}
	},

	//Prefer creating new modules when appropriate, rather than adding functions here
	//If you need something to be globally accessible, you can assign an imported module to this namespace
	//I.e import Contact from './contact';
	//    window.namespace.Contact = Contact;
};

window.glide.init();

// SVG Sprite Loader
// eslint-disable-next-line no-unused-vars
const __sprite__ = {
	path: '../../resources/images/icons/**/*.svg', //Source SVGs path relative to the compiled js (dist/js)
	name: 'images/icons.svg', //Compiled file path relative to dist/
};

svgxhr({
	filename: './wp-content/themes/class/dist/images/icons.svg', //URL to fetch
});
