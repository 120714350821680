import Accordion from '../lib/Accordion';

export default {
	init() {
		window.addEventListener('resize', () => this.resize, {passive: true});
		document.addEventListener('readystatechange', () => {
			if(document.readyState === 'complete') {
				this.resize();
			}
		});

		if(document.readyState === 'complete') {
			this.resize();
		}
	},
	resize() {
		const sections = document.querySelectorAll('.section--staggered-grid');

		if(sections.length) {
			sections.forEach(el => {
				if(window.outerWidth > 767) {
					if(typeof el.accordion !== 'undefined') {
						el.accordion.destroy();
					}

					if(!el.staggered) {
						let header = el.querySelector('.section__header'),
							height = header.offsetHeight + 90;

						el.querySelector('.grid').style.height =
							(el.querySelector('.grid').offsetHeight + height) + 'px';

						el.querySelector('.grid').style.minHeight = '420px';
						el.staggered = true;
					}
				} else {
					el.querySelector('.grid').style.height = 'auto';
					el.staggered = false;

					if(el.querySelector('.grid') !== null) {
						el.accordion = new Accordion(el.querySelector('.grid'), {collapse: true});
					}
				}
			});
		} else {
			console.warn('No .section--staggered-grid found. Exiting.');
		}
	}
}
