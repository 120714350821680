export default {
	headerHeight: 0,
	init: function(){
		if(document.getElementById('page-header') === null){
			console.warn('#page-header does not exist. Exiting Affix');
			return;
		}

		if(document.getElementById('main-nav') === null){
			console.warn('#main-nav does not exist. Exiting Affix');
			return;
		}

		this.headerHeight = document.getElementById('page-header').offsetHeight;
		this.header = document.getElementById('page-header');

		window.addEventListener('scroll', this.scroll.bind(this), { passive : true });
		window.addEventListener('resize', this.resize.bind(this), { passive : true });

	},
	scroll: function () {
		if(window.pageYOffset > this.headerHeight && !this.header.classList.contains('affixed')){
			this.header.classList.add('affixed');
		}

		if(window.pageYOffset < this.headerHeight && this.header.classList.contains('affixed')){
			this.header.classList.remove('affixed');
		}
	},
	resize: function(){
		this.headerHeight = document.getElementById('page-header').offsetHeight;
	}
}
