
//import SplitText from '../lib/SplitText';
import ScrollMagic from '../lib/ScrollMagic';
import { CountUp } from 'countup.js';

// noinspection JSUnusedGlobalSymbols
export default {
	controller: null,
	timeline: null,

	initAnimations: function(ele) {
		// let $ele = $(ele);

		if(window.scrollMagicController) {
			window.scrollMagicController.destroy();
		}

		window.scrollMagicController = new ScrollMagic.Controller();

		if(ele.querySelector("[data-animation]") !== null){
			ele.querySelectorAll("[data-animation]").forEach(item => {
				if(typeof item.dataset.animation === 'undefined' || !item.dataset.animation.length){
					return;
				}

				if(typeof this[item.dataset.animation] !== 'function') {
					return;
				}

				this.initAnimation(item);
			});
		}
	},

	initAnimation: function(element) {
		let trigger = element.dataset.trigger,
			args = {
				triggerElement: element,
			};

		switch(trigger) {
			case 'bottom' :
				args.triggerHook = 1.0;
				break;

			case 'top' :
				args.triggerHook = 0.2;
				break;

			default:
				args.triggerHook = 0.7;

				break;
		}

		let scene = new ScrollMagic.Scene(args)
			.on('start', () => {
				this[element.dataset.animation](element);
				scene.destroy();
			})
			.addTo(window.scrollMagicController)
		//.addIndicators();
	},

	titleAnimation: function(ele) {
		// let lines = new SplitText(ele, {type: 'lines'}),
		// 	delay = 0;

		// ele.style.opacity = 1;
		// lines.lines.forEach(line => {
		// 	let classList = 'animated small-fade-in-up duration--3 delay--' + delay;

		// 	line.style.opacity = 0;
		// 	line.classList.add(...classList.split(' '));

		// 	delay++;
		// });
	},

	countUp: function(element){
		if(typeof element.dataset.number === 'undefined'){
			return false;
		}

		let start = parseInt(element.innerText.replace(/,/g, "")),
			end   = parseInt(element.dataset.number.replace(/,/g, ""));

		element.style.opacity = 1;

		if(isNaN(end) || isNaN(start)){
			return false;
		}

		let demo = new CountUp(element, end, {
			startVal: start,
			duration: 7,
		});

		if (!demo.error) {
			demo.start();
		} else {
			console.error(demo.error);
			return false;
		}

		return true;
	},

	addClass: function(element){
		if(typeof element.dataset.addClass === 'undefined'){
			return false;
		}

		element.classList.add(...element.dataset.addClass.split(' '));

		return true;
	},

	childAddClass: function(element){
		if(element.querySelector('[data-child-class]') === null){
			return;
		}

		element.querySelectorAll('[data-child-class]').forEach(childElement => {
			childElement.classList.add(...childElement.dataset.childClass.split(' '));
		});

		element.style.opacity = 1;
	}
};
