import API from './api';
import { debounce } from 'debounce';

export default class PostcodeLookup {
	constructor(element) {
		this.query = element.querySelector('.lookup__query');
		this.value = element.querySelector('.lookup__value');
		this.results = element.querySelector('.lookup__results');
		this.resultsContainer = this.results.closest('.form__group');

		if(!this.query) {
			throw new TypeError('[PostcodeLookup] No .lookup__query found');
		}

		if(!this.value) {
			throw new TypeError('[PostcodeLookup] No .lookup__value found');
		}

		if(!this.results) {
			throw new TypeError('[PostcodeLookup] No .lookup__results found');
		}

		if(!this.resultsContainer) {
			throw new TypeError('[PostcodeLookup] No .form__group parent of .lookup__results found');
		}

		this.resultsContainer.style.display = 'none';

		this.query.addEventListener('input', debounce(e => {
			this.lookupPostcode(this.query.value);
		}, 500));

		this.results.addEventListener('change', debounce(e => {
			this.setValue(this.results.value);
		}, 500));
	}

	lookupPostcode(postcode) {
		this.clearResults();
		this.resultsContainer.style.display = '';

		if(!postcode) {
			return;
		}

		API.lookupPostcode(postcode).then(addresses => {
			if(!addresses || !addresses.length) {
				return;
			}

			this.populateResults(addresses);
		}).catch(e => {
			this.clearResults();
		});
	}

	clearResults() {
		while(this.results.options.length > 0) {
			this.results.remove(0);
		}

		if(this.results.choices) {
			this.results.choices.clearStore();
			this.results.choices.hideDropdown();
			this.results.choices.disable();
		}

		this.clearValue();
	}

	populateResults(results) {
		let choices = [];

		for(let i = 0; i < results.length; i++) {
			let address = results[i],
				option = document.createElement('option');

			option.value = JSON.stringify(address);
			option.innerText = address.address;

			choices.push({
				value: JSON.stringify(address),
				label: address.address,
			});

			this.results.add(option);
		}

		if(this.results.choices) {
			this.results.choices.setChoices(choices);
			this.results.choices.enable();
			this.results.choices.showDropdown();
		}
	}

	setValue(value) {
		this.value.value = value;
	}

	clearValue() {
		this.value.value = null;
	}
}
