import API from './api';

class Calculator {
	constructor(element, opts = {}) {
		this.wrapper = element;
		this.form = this.wrapper.querySelector('.calculator-form');
		this.result = this.wrapper.querySelector('.calculator-result__amount');
		this.people = this.form.querySelector('[name="people"]');

		this.people.addEventListener('change', this.changePrice.bind(this));

		this.changePrice();
	}

	changePrice() {
		this.getQuote(parseInt(this.people.value)).then(total => {
			this.result.innerText = '£' + total;
		});
	}

	getQuote(people) {
		return API.getQuote({
			address: {
				udprn: '14484719',
				address: '50, Old Lansdowne Road, Manchester',
				address1: '50 Old Lansdowne Road',
				address2: '',
				town: 'Manchester',
				postcode: 'M20 2WU',
				raw: {
					organisation: null,
					subPremise: null,
					buildingName: null,
					buildingNumber: "50",
					thoroughfare: "OLD LANSDOWNE ROAD",
					town: "MANCHESTER",
					postcode: "M20 2WU",
				},
			},
			capacity: people,
			period: 12,
			checkAvailability: false,
		}).then(response => {
			let gas = response.gas ? response.gas.tenant_week : 0,
				elec = response.electricity ? response.electricity.tenant_week : 0,
				internet = response['fibre-broadband'] ? response['fibre-broadband'].tenant_week : 0,
				total = Number.parseFloat(gas) + Number.parseFloat(elec) + Number.parseFloat(internet);

			return total.toFixed(2);
		});
	}
}

export default Calculator;
