import API from './api.js';

/**
 * Used on the Broadband Coverage Checker page. Provided functionality for looking up coverage in an area, by
 * postcode. Also creates and manages the on-page Google map.
 */
export default class CoverageChecker {

    constructor(element) {
        this.form = element.querySelector('.form');
        this.resultsContainer = element.querySelector('.results');
        this.results = this.resultsContainer.querySelector('.results__list');
        this.iconBase = window.location.origin + "/wp-content/themes/class/gl/assets/img/";
        this.postcodeErrorValidationMessage = 'Please enter a valid, UK postcode';
        this.postcodeErrorTechMessage = 'Sorry, something went wrong. Please try again later';
        this.zoomStart = 7;
        this.zoomLocation = 16;
        this.latLongDefault = new google.maps.LatLng(52.6184892, -2.3180478);

        //	Icons for map markers
        this.iconGreen = {
            url: this.iconBase + "map-pin.png",
            scaledSize: new google.maps.Size(34, 47)
        };
        this.iconPurple = {
            url: this.iconBase + "map-pin-purple.png",
            scaledSize: new google.maps.Size(34, 47)
        };
        this.iconCluster = {
            url: this.iconBase + "map-pin-cluster.png",
            scaledSize: new google.maps.Size(34, 59)
        };

        //	Check for key DOM elements
        if (!this.form) {
            throw new TypeError('[CoverageChecker] No .form found');
        }
        if (!this.resultsContainer) {
            throw new TypeError('[CoverageChecker] No .results found');
        }
        if (!this.results) {
            throw new TypeError('[CoverageChecker] No .results__list found');
        }

        //	Set up marker arrays
        this.marker = [];
        this.markerPostcode = [];
        //  Set the map and show it
        this.createMap();
        this.setDefaultMarkers();
        this.showResultsMessage('default');

        //	Listens for activity in the postcode field and removes the validation error, if visible
        this.form.elements.postcode.addEventListener('focus', e => {
            e.preventDefault();
            console.log('Postcode field is in focus');
            this.postcodeErrorHide();
        });

        //	Listens for the submission of the postcode lookup form
        this.form.addEventListener('submit', e => {
            e.preventDefault();

            //  Check the entered postcode
            let postcodeElem = this.form.elements.postcode;
            let postcode = postcodeElem.value.replace(/\s+/g, '');
            let previousVal = postcodeElem.getAttribute('data-prev');

            //	End now if the entered postcode is the same as the last one entered
            if (previousVal.length && postcode === previousVal)
                return false;

            //	Validate the postcode entered
            if (!/^[A-Z0-9]+$/i.test(postcode)) {
                // Bad postcode
                this.form.elements.postcode.setAttribute('data-prev', '');
                this.errorState(this.postcodeErrorValidationMessage);
                return false;
            }

            //	Set the entered postcode as the previous one, at this point
            postcodeElem.setAttribute('data-prev', postcode);
            this.mapMarkersClear(this.markerPostcode);
            //  Send the postcode to the Google API to get the lat/long for the location then set the map marker for the postcode
            this.mapMarkerSet(postcode);
            //  Do the coverage lookup
            this.search(postcode);
        });
    }

    /**
     * Create the Google map that shows the coverage points
     */
    createMap() {
        let self = this;
        self.map = new google.maps.Map(document.getElementById("gl-comp-map-container"), {
            center: self.latLongDefault,
            zoom: self.zoomStart,
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            zoomControl: true,
            mapTypeControl: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "color": "#250d47"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural.landcover",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e4e3df"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        {
                            "color": "#c1ddcc"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "color": "#250d47"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "color": "#e8eaed"
                        },
                        {
                            "visibility": "on"
                        },
                        {
                            "weight": 1
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "weight": 1
                        }
                    ]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "weight": 1
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "stylers": [
                        {
                            "color": "#250d47"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "color": "#7255a5"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        });
    }


    /**
     * Does an API lookup to check coverage availability for the given postcode
     */
    search() {
        let postcode = this.form.elements.postcode.value;
        this.form.search.disabled = true;

        //	API lookup
        API.checkCoverage(postcode).then(response => {
            //	API Response should look like: {"available":true,"distance_150m":true,"distance_300m":true}
            //alert(response);
            if (undefined !== response.error) {
                //	Error message in API response. Error message given, so we can show it!
                console.log('Error: ' + response.error);
                this.errorState(response.error);
            } else if (undefined === response.available) {
                //	available field missing in lookup response - error!
                console.log('available field missing in lookup response');
                this.errorState(this.postcodeErrorTechMessage);
            } else {
                if (!response.available) {
                    //	Not available for this postcode
                    console.log('Not available for this postcode');
                    this.showResultsMessage('available-none');
                } else {
                    if (undefined === response.distance_150m || undefined === response.distance_300m) {
                        //	1+ missing distance fields in lookup response - error!
                        console.log('1+ missing distance fields in lookup response');
                        this.errorState(this.postcodeErrorTechMessage);
                    } else {
                        //	Coverage is available and aLl fields are in the response
                        if (response.available && response.distance_150m && !response.distance_300m) {
                            //	Only 150m is available
                            console.log('Only 150m is available');
                            this.showResultsMessage('available-150m');
                        } else if (response.available && !response.distance_150m && response.distance_300m) {
                            //	Only 300m is available
                            console.log('Only 300m is available');
                            this.showResultsMessage('available-300m');
                        } else if (response.available && response.distance_150m && response.distance_300m) {
                            //	Both 150m and 300m are available
                            console.log('Both 150m and 300m are available');
                            this.showResultsMessage('available-both');
                        } else {
                            console.log('Unexpected response from postcode lookup');
                            this.errorState(this.postcodeErrorTechMessage);
                        }
                    }
                }
            }
        }).catch(e => {
            console.error(e);
            this.errorState(this.postcodeErrorTechMessage);
        }).finally(() => {
            this.form.search.disabled = false;
        });
    }

    /**
     * Clears the given set of markers from the map
     * @param markersToClear
     */
    mapMarkersClear(markersToClear) {
        for (let i = 0; i < markersToClear.length; i++) {
            markersToClear[i].setMap(null);
        }
        markersToClear = [];
    }


    /**
     * Sets a marker on the map for the given postcode. It looks up the lat and long with Geocoder
     * @param postcode
     */
    mapMarkerSet(postcode) {
        let geocoder = new google.maps.Geocoder();
        let self = this;

        geocoder.geocode({'address': 'postcode ' + postcode}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                let latitude = results[0].geometry.location.lat();
                let longitude = results[0].geometry.location.lng();
                let latLong = new google.maps.LatLng(latitude, longitude);
                console.info("Latitude: " + latitude + "\nLongitude: " + longitude)

                //  Create the map marker for the derived lat and long
                let m = new google.maps.Marker({
                    map: self.map,
                    icon: self.iconGreen,
                    animation: google.maps.Animation.DROP,
                    position: latLong,
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 1,
                });
                self.marker.push(m);
                self.markerPostcode.push(m);

                //	Center the map on the marker and zoom to it
                self.map.setCenter(latLong);
                self.map.setZoom(self.zoomLocation);

                //Animate marker
                m.setAnimation(google.maps.Animation.BOUNCE);
                setTimeout(function () {
                    m.setAnimation(null);
                }, 1000);
            } else {
                console.warn("Could not get lat/long for postcode: " + postcode)
            }
        });
    }

    /**
     * Sets the state of some parts of the page when an error occurs
     * @param message
     */
    errorState(message) {
        //this.form.elements.postcode.setAttribute('data-prev', '');
        this.showResultsMessage('default');
        this.postcodeErrorShow(message);
        this.mapMarkersClear(this.markerPostcode);
        this.map.setCenter(this.latLongDefault);
        this.map.setZoom(this.zoomStart);
    }


    /**
     * Looks up the default marker locations via API and sets them as markers on the map
     */
    setDefaultMarkers() {
        let self = this;
        let theMap = self.map;
        let theMarkers = self.marker;

        //	Get the default marker positions via API
        API.coverageLocations().then(response => {
            //	If there is no response element
            if (undefined === response || !response.length) {
                console.warn('Error. Bad response for default coverage locations');
            }

            //	Loop each marker position and create marker for it
            for (let i = 0; i < response.length; i++) {
                let m = new google.maps.Marker({
                    map: theMap,
                    icon: self.iconPurple,
                    animation: google.maps.Animation.DROP,
                    position: new google.maps.LatLng(response[i].Latitude, response[i].Longitude),
                });
                theMarkers.push(m);
            }

            //	Build the renderer for the cluster icon
            const renderer = {
                render({count, position}) {
                    return new google.maps.Marker({
                        label: {text: String(count), color: "white", fontSize: "12px"},
                        position,
                        icon: self.iconCluster,
                    })
                }
            }

            // Add a marker clusterer to manage the markers.
            let mc = new markerClusterer.MarkerClusterer({
                map: theMap,
                markers: theMarkers,
                renderer: renderer,
            });
            console.log(mc);

        }).catch(e => console.error(e));
    }


    /**
     * Shows one of the results messages, based on the given messageLabel
     * @param messageLabel
     */
    showResultsMessage(messageLabel) {
        let resultsElem = $('div#coverage-checker-results_' + messageLabel);
        //	Only fade in the message if it isn't the one that is already visible
        if (!resultsElem.is(':visible')) {
            $('div.coverage-checker-results').hide();
            resultsElem.fadeIn(200);
        }
    }


    /**
     * Hides the error message under the postcode form field and removes the red border from the input
     */
    postcodeErrorHide() {
        let validationMessageElem = $('div#postcode-valid-err');
        if (validationMessageElem.is(':visible')) {
            validationMessageElem.fadeOut(100);
            validationMessageElem.html('');
            $('input#postcode').removeClass('postcode-valid-err-border');
        }
    }


    /**
     * Shows an error message under the postcode form field and sets the input border red
     * @param errorMessage
     */
    postcodeErrorShow(errorMessage) {
        let validationMessageElem = $('div#postcode-valid-err');
        if (!validationMessageElem.is(':visible')) {
            validationMessageElem.html(errorMessage);
            validationMessageElem.fadeIn(100);
            $('input#postcode').addClass('postcode-valid-err-border');
        }
    }
}
