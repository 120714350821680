import { $ } from './Functions.js'

class Accordion {

	constructor(element, opts = {}) {
		this.element = element;
		this.selector = '.expandable__item';

		if(opts.selector) {
			this.selector = opts.selector;
		}

		if(opts.active) {
			this.active = opts.active
		}

		this.items = [...element.querySelectorAll(this.selector)];

		this.opacity = opts.opacity || false;
		this.collapse = opts.collapse || false;

		this.isBreakpoint = !!opts.breakpoint;

		if(this.isBreakpoint) {
			this.breakpoint = window.matchMedia('(max-width:' + (opts.breakpoint) + 'px)');

			this.isDestroyed = false;
			this.isInit = false;
		}

		this.functions = [];

		this.init()
	}

	init() {

		if(this.isBreakpoint) {

			if(this.breakpoint.matches) {
				this.initTabs()
			}

			window.addEventListener('resize', () => {
				if(this.breakpoint.matches) {
					if(!this.isInit) {
						this.initTabs()
					}
				} else {
					if(!this.isDestroyed) {
						this.destroy()
					}
				}
			})

		} else if(!this.isBreakpoint) {

			this.initTabs()

		}
	}

	initTabs() {

		if(this.active) {
			this.setActive(this.active - 1)
		}

		this.items.forEach((item, index) => {
			const first = item.children[0];
			const last = item.children[item.children.length - 1];

			if(index !== this.active - 1) {
				$.expand(last, {duration: 200, direction: 'close', opacity: this.opacity});
			}

			let _onClick = (item, first, last, index) => {
				if(this.collapse) {
					this.removeOpened(index)
				}

				$.expand(last, {duration: 200, direction: 'toggle', opacity: this.opacity});

				if(item.dataset.active === 'true') {
					item.dataset.active = 'false';
					item.classList.remove('is-active')
				} else {
					item.dataset.active = 'true';
					item.classList.add('is-active')
				}
			};

			this.functions.push({
				first,
				onClick() {
					_onClick(item, first, last, index)
				}
			})

		});

		this.functions.forEach((f) => {
			f.first.addEventListener('click', f.onClick)
		});

		this.isDestroyed = false;
		this.isInit = true
	}

	setActive(index) {

		const item = this.items[index];
		const last = item.children[item.children.length - 1];
		$.expand(last, {duration: 200, direction: 'open', opacity: this.opacity});
		item.dataset.active = 'true';
		item.classList.add('is-active')

	}

	removeOpened(index) {
		this.items.forEach((item, i) => {

			if(i !== index) {
				const last = item.children[item.children.length - 1];

				$.expand(last, {duration: 200, direction: 'close', opacity: this.opacity});

				item.dataset.active = 'false';
				item.classList.remove('is-active')
			}
		})
	}

	reset() {
		this.destroy();
		if(this.breakpoint.matches) {
			this.initTabs()
		}
	}

	destroy() {
		this.items.forEach((item) => {

			item.dataset.active = '';
			item.classList.remove('is-active');


			const last = item.children[item.children.length - 1];

			last.classList.remove('is-expanded');
			last.setAttribute('style', '')

		});

		this.functions.forEach((f) => {
			f.first.removeEventListener('click', f.onClick)
		});

		this.functions = [];

		this.isDestroyed = true;
		this.isInit = false
	}

}

export default Accordion
